<template>
  <div class="appContainer applyBox flex-between">
    <div class="leftBox bb ">
      <div class="flex-between header">
        <el-input
          placeholder="请输入内容"
          v-model.trim="leftForm.filter"
          @input="searchFn"
          clearable
        />
        <el-tooltip effect="dark" content="新增" placement="top-start">
          <el-button
            icon="el-icon-plus"
            style="margin-left:10px"
            class="font18"
            type="text"
            @click="addFn"
          />
        </el-tooltip>
        <el-tooltip effect="dark" content="刷新列表" placement="top-start">
          <el-button
            icon="el-icon-refresh"
            style="margin-left:10px"
            class="font18"
            type="text"
            @click="refreshListFn"
          />
        </el-tooltip>
      </div>
      <el-scrollbar
        class="list"
        wrap-class="scrollbar-wrapper"
        v-if="applyList.length"
        v-loading="loadingList"
      >
        <span
          v-for="(item, key) in applyList"
          class="pointer  text flex-between"
          :key="key"
          @click.stop="selectApplyFn(item)"
          :class="{ active: currentId == item.appId }"
        >
          <span>{{ item.name }}</span>
          <el-popconfirm
            title="确定删除该应用吗？"
            @confirm="deleApplyFn(item)"
          >
            <el-button
              type="danger"
              size="mini"
              class="deleBtn "
              slot="reference"
              >删除</el-button
            >
          </el-popconfirm>
        </span></el-scrollbar
      >
      <p v-else class="empty bb text-center">暂无数据</p>
    </div>
    <div class="rightBox bb" v-loading="loadingDetial">
      <div v-if="currentId">
        <el-card class="box-card" v-for="item in cardList" :key="item.id">
          <div slot="header">
            <span class="fW600">{{ item.name }}</span>
          </div>
          <template v-if="item.id == 'base'">
            <div class="line-box">
              <b>应用名称：</b>
              <span class=" pointer text" @dblclick="dbItemFn()">
                <el-tooltip
                  effect="dark"
                  content="双击编辑名称"
                  placement="top-start"
                >
                  <label class="" v-show="!isEditApplyName">{{
                    form.name
                  }}</label>
                </el-tooltip>
                <editInput
                  ref="editInputChild"
                  v-show="isEditApplyName"
                  :name="form.name"
                  :width="'300px'"
                  :placeholder="'请输入应用名称'"
                  :word_limit_length="64"
                  :inputType="'text'"
                  @inputBlur="inputBlurFn"
                />
              </span>
            </div>
            <div class="line-box">
              <b>应用编码：</b>
              <span>{{ applyDetial.code }}</span>
            </div></template
          >
          <template v-if="item.id == 'apply'">
            <applySourceTable
              :tableData="tableData"
              :appId="currentId"
              ref="sourceTable"
            />
          </template>
        </el-card>
      </div>
      <el-empty
        v-if="!currentId"
        style="margin-top:200px"
        description="暂无数据，请点击左侧列表"
      />
    </div>
    <addDialog
      :showDialog="showAddDialog"
      @closeDialog="closeAddDialogFn"
      @sureDialog="sureAddDialogFn"
    />
  </div>
</template>

<script>
import {
  // A_apply,
  add_edit_apply,
  get_apply_source,
  remove_apply,
  apply_ifrepeat,
  tenant_ifrepeat,
} from '@/api/apply'
import { mapGetters } from 'vuex'
export default {
  name: 'Apply',
  components: {
    addDialog: () => import('./components/add_apply_dialog.vue'),
    // authoriseDialog: () => import('./components/authorise_dialog.vue'),
    applySourceTable: () => import('./components/apply_source_table.vue'),
    editInput: () => import('@/views/components/editInput'),
  },
  data() {
    return {
      leftForm: {
        pageNumber: 1,
        pageSize: 100000,
        filter: '',
        sort: '',
      },
      loadingList: false,
      isEditApplyName: false,
      currentId: null,
      applyDetial: {},
      loadingDetial: false,
      showAddDialog: false,
      form: { name: '' },
      cardList: [
        { name: '基本信息', id: 'base' },
        { name: '应用资源', id: 'apply' },
      ],
      tableData: [],
      showAuthoriseDialog: false,
    }
  },
  created() {
    this.getApplyList()
    // let that = this
    // window.onresize = () => {
    //   return (() => {
    //     that.maxHeight = window.innerHeight - 300
    //     console.log(this.maxHeight, 'maxHeight===', window.innerHeight)
    //   })()
    // }
  },

  methods: {
    deleApplyFn(row) {
      remove_apply({ id: row.appId.toString() }).then((res) => {
        this.$message.success('删除成功')
        this.currentId = ''
        this.applyDetial = null
        this.getApplyList()
      })
    },
    async getApplySource(cb) {
      this.tableData = []
      const res = await get_apply_source({ appId: this.currentId })
      this.tableData = Object.values(this.setTableData(res)) || []
      // console.log(this.tableData, ' this.tableData===')
      this.$nextTick(() => {
        this.$refs.sourceTable.maxHeight = window.innerHeight - 350
        // console.log(this.$refs.sourceTable.maxHeight)
      })
    },
    setTableData(arr) {
      let newList = {}
      let noParentNodes = {
        name: '未知资源',
        children: [],
        code: '未知code',
        parentNode: 'none',
      }

      arr.forEach((ele) => {
        let obj = {}
        obj = ele
        obj.tenantId = ele.tenantId.toString()
        obj.tenantPermissionId = ele.tenantPermissionId.toString()
        obj.rootId = ele.rootId.toString()
        if (ele.parentId == undefined) {
          obj.children = []
          newList[obj.rootId] = obj
        } else {
          if (newList[ele.parentId]) {
            obj.parentId = ele.parentId.toString()
            newList[ele.parentId].children.push(obj)
          } else {
            obj.optionBtn = true
            noParentNodes.children.push(obj)
          }
        }
      })

      return noParentNodes.children.length
        ? { ...newList, noParentNodes }
        : newList
    },
    inputBlurFn(v) {
      if (v == '') {
        this.$message.warning('应用名称不能为空')
        return
      }
      this.form.name = v
      this.add_edit_apply({ ...this.applyDetial, ...this.form }, () => {
        this.isEditApplyName = false
        this.$message.success('保存成功')
        this.getApplyList()
      })
    },

    dbItemFn() {
      this.isEditApplyName = true
      this.$nextTick((_) => {
        this.$refs.editInputChild[0].$refs.nameInput.$refs.input.focus()
      })
    },
    closeAddDialogFn() {
      this.showAddDialog = false
    },
    sureAddDialogFn(v) {
      this.add_edit_apply(v, () => {
        this.showAddDialog = false
        this.$message.success('新增成功')
        this.getApplyList()
      })
    },

    searchFn() {
      this.getApplyList()
    },
    selectApplyFn(v) {
      this.currentId = v.appId.toString()
      this.applyDetial = v
      this.form.name = this.applyDetial.name
      this.loadingDetial = false
      this.getApplySource()
    },
    addFn() {
      this.showAddDialog = true
    },
    refreshListFn() {
      let timer = setTimeout(() => {
        this.getApplyList(() => {
          this.$message.success('刷新成功')
        })
      }, 200)
    },
    apply_ifrepeat(name, value, cb) {
      apply_ifrepeat({
        id: !this.showAddDialog ? this.currentId : '',
        name: name,
        value: value,
      }).then((res) => {
        cb(res)
      })
    },
    async add_edit_apply(params, cb) {
      this.apply_ifrepeat('name', params.name, (res1) => {
        if (res1) {
          this.$message.warning('应用名已存在')
          return
        } else {
          this.apply_ifrepeat('code', params.code, (res2) => {
            if (res2) {
              this.$message.warning('编码已存在')
              return
            } else {
              add_edit_apply(params).then((res) => {
                cb && cb()
              })
            }
          })
        }
      })
    },
    // async get_A_apply() {
    //   this.loadingDetial = true
    //   try {
    //     const res = await A_apply({ appId: this.currentId })
    //     this.applyDetial = res
    //     this.form.name = this.applyDetial.name
    //     this.loadingDetial = false
    //   } catch {
    //     this.loadingDetial = false
    //   }
    // },
    getApplyList(cb) {
      this.$store
        .dispatch('apply/fetchApplyList', this.leftForm)
        .then(() => {
          this.loadingList = false
          cb && cb()
        })
        .catch(() => {
          this.loadingList = false
        })
    },
  },
  computed: {
    ...mapGetters(['applyList']),
  },
}
</script>

<style lang="scss" scoped>
::v-deep .el-scrollbar__wrap {
  overflow-x: hidden;
}
.header {
  height: 40px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e5e5e5;
}
.list {
  margin-top: 8px;
  height: calc(100% - 60px);
  .text {
    padding: 5px 10px;
    line-height: 20px;
    margin-bottom: 8px;
    .deleBtn {
      display: none;
      padding: 2px;
    }
    &:hover {
      background: #e8f1ff;
      .deleBtn {
        display: inline-block;
      }
    }
  }
  .active {
    background: #e8f1ff;
    .deleBtn {
      display: inline-block;
    }
  }
}

.box-card {
  min-height: 100px;
  margin-top: 10px;
  .line-box {
    // text-align: center;
    line-height: 32px;
    margin-bottom: 10px;
    b {
      min-width: 140px;
      text-align: right;
      display: inline-block;
      margin-right: 5%;
    }
    .text {
      min-width: 200px;
      text-align: left;
      display: inline-block;
    }
  }
}

// }
</style>
